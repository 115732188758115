/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconCircleCloseElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-circle-close';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12,21.75A9.75,9.75,0,1,1,21.75,12,9.76,9.76,0,0,1,12,21.75Zm0-18A8.25,8.25,0,1,0,20.25,12,8.26,8.26,0,0,0,12,3.75Z"/>
    <path d="M13.06,12l2.47-2.47a.75.75,0,0,0-1.06-1.06L12,10.94,9.53,8.47A.75.75,0,0,0,8.47,9.53L10.94,12,8.47,14.47a.75.75,0,1,0,1.06,1.06L12,13.06l2.47,2.47a.75.75,0,0,0,1.06-1.06Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconCircleCloseElement.is,
    window.JhaIconCircleCloseElement);
export default window.JhaIconCircleCloseElement;
